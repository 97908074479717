import React, { useEffect, useRef } from 'react'

// Physics
import { Physics } from '@react-three/cannon'

// Three
import { extend, useThree } from '@react-three/fiber'
import { PositionalAudio, useAspect, useVideoTexture } from '@react-three/drei'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls'

// Prefabs
import { Player } from '../prefabs/Player'
// import { Skybox } from '../prefabs/Skybox'

import { Room } from '../prefabs/Room'
import { Speaker } from '../prefabs/Speaker'
import { FrontSide } from 'three'

extend({ PointerLockControls })

export const DefaultScene = ({ ready }) => {
	const { camera, gl } = useThree()
	const controls = useRef()

	useEffect(() => {
		camera.layers.enable(0)
		camera.layers.enable(1)
	}, [camera])

	useEffect(() => {
		const handleFocus = () => {
			controls.current.lock()
		}
		document.addEventListener('click', handleFocus)

		return () => {
			document.removeEventListener('click', handleFocus)
		}
	}, [gl])

	console.log(ready)

	return (
		<>
			<fog
				attach='fog'
				color='lightgray'
				near={0.1}
				far={25}
			/>
			{/** Skybox */}
			{/* <Skybox /> */}
			{/** Pointer lock */}
			<pointerLockControls
				ref={controls}
				args={[camera, gl.domElement]}
			/>
			{/** Lighting */}
			<directionalLight
				position={[0, 2, 0]}
				intensity={0.5}
				castShadow
			/>
			<pointLight
				position={[0, 2, -3]}
				intensity={0.4}
				castShadow
			/>
			<pointLight
				position={[0, 2, 3]}
				intensity={0.4}
				castShadow
			/>
			<ambientLight intensity={0.3} />
			{/** Physic objects */}
			<Physics
				gravity={[0, -9, 0]}
				tolerance={0}
				iterations={50}
				broadphase={'SAP'}
			>
				{/** Player */}
				<Player />
				{/** Floor */}
				<Room />
				<Speaker
					position={[-4.5, 0, 9.5]}
					url={'5.wav'}
				/>
				<Speaker
					position={[4.5, 0, 9.5]}
					rotation={[0, Math.PI, 0]}
					url={'6.wav'}
				/>
				<Speaker
					position={[-4.5, 0, 0]}
					url={'3.wav'}
				/>
				<Speaker
					position={[4.5, 0, 0]}
					rotation={[0, Math.PI, 0]}
					url={'4.wav'}
				/>
				<Speaker
					position={[-4.5, 0, -9.5]}
					url={'1.wav'}
				/>
				<Speaker
					position={[4.5, 0, -9.5]}
					rotation={[0, Math.PI, 0]}
					url={'2.wav'}
				/>
				{/* <Screen url='test.mov' /> */}
			</Physics>
		</>
	)
}
