import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import React, { useRef, useEffect, Suspense } from 'react'
import { Sphere, useGLTF, PositionalAudio } from '@react-three/drei'

function Analyzer({ sound }) {
  // <Analyzer /> will not run before everything else in the suspense block is resolved.
  // That means <PositionalAudio/>, which executes async, is ready by the time we're here.
  // The next frame (useEffect) is guaranteed(!) to access positional-audios ref.
  const mesh = useRef()
  const analyser = useRef()
  useEffect(
    () => void (analyser.current = new THREE.AudioAnalyser(sound.current, 32)),
    []
  )
  useFrame(() => {
    if (analyser.current) {
      const data = analyser.current.getAverageFrequency()
      mesh.current.material.color.setRGB(data / 320, data / 160, data / 80)
      mesh.current.material.transparent = true
      mesh.current.material.opacity = data / 100
      mesh.current.scale.x =
        mesh.current.scale.y =
        mesh.current.scale.z =
          data / 100
    }
  })
  return (
    <Sphere
      ref={mesh}
      args={[1, 64, 64]}
      position={[0.5, 0.5, 0]}
    >
      <meshBasicMaterial />
    </Sphere>
  )
}

function PlaySound({ url }) {
  // This component creates a suspense block, blocking execution until
  // all async tasks (in this case PositionAudio) have been resolved.
  const sound = useRef()
  return (
    <Suspense fallback={null}>
      <PositionalAudio
        autoplay
        url={url}
        ref={sound}
        distance={1.5}
        position={[0, 1, 0]}
      />
      <Analyzer sound={sound} />
    </Suspense>
  )
}

export function Speaker(props) {
  const { nodes, materials } = useGLTF('/speaker.glb')
  return (
    <group
      {...props}
      dispose={null}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials.Material}
      />
      <PlaySound url={props.url} />
    </group>
  )
}

useGLTF.preload('/speaker.glb')
