import React, { useState, Suspense, useEffect } from 'react'
// import './index.css';
import { Canvas } from '@react-three/fiber'

//Scene
import { DefaultScene } from './scene/DefaultScene'

// Styles
import './styles.css'

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: '1.25rem',
      }}
    >
      <p>Loading...</p>
    </div>
  )
}

export default function App() {
  const [loaded, setLoaded] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 800

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  function toneStart() {
    // Tone.start()
    console.log('layout started!')
    setLoaded(true)
  }

  return (
    <div id='pageWrap'>
      {width < breakpoint ? (
        <div
          id='loading'
          style={{
            display: 'flex',
          }}
        >
          <div
            id='loadingText'
            style={{ margin: '0 60px' }}
          >
            <p>Please visit this page on desktop to run the experience</p>
          </div>
        </div>
      ) : (
        <>
          <div
            id='canvasWrap'
            style={{ display: loaded ? 'flex' : 'none' }}
          >
            <Suspense fallback={<Loading />}>
              <Canvas camera={{ fov: 70, rotation: [0, 0, 0] }}>
                <DefaultScene ready={loaded} />
              </Canvas>
            </Suspense>
          </div>
          <div
            id='loading'
            onClick={toneStart}
            style={{
              display: loaded ? 'none' : 'flex',
              pointerEvents: loaded ? 'none' : '',
            }}
          >
            <div id='loadingText'>
              <p>
                Virtual installation of a spacial
                <br />
                soundscape for a{' '}
                <a
                  href='https://candamill.com/pages/chapter-v-mott-street'
                  target='blank'
                >
                  Candamill
                </a>
                <br />
                (Headphones recommended!)
                <br />
                <br />
                Use the arrow or W,A,S,D keys to move
                <br />
                and the mouse to look around.
                <br />
                press 'esc' to exit
                <br />
                <br />
                click anywhere to
                <br />
                continue...
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
